<template lang="pug">
  b-card.manager-machines-reports-list.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-card-header.bg-transparent.border-0
      wc-tables-header(
        :title="$t('activerecord.models.machine/report.other')"
        @click-data-clean-fetch='dataCleanFetch'
        @click-data-fetch='dataFetch')

    b-card-body
      b-row
        b-col
          wc-tables-filter(
            :filter.sync='filter'
            :p-page.sync='pagination.pPage'
            :p-page-options='pagination.pPageOptions'
            @submit='dataFetch'
            @submit-clear='filterClear')

      b-row
        b-col
          transition(name='fade')
            b-row(cols='1' cols-lg='2')
              template(v-for='(item, index) in items')
                b-col.mb-4
                  b-card.reports-item.card-white.card-no-gutters.shadow(no-body)
                    b-card-body.d-flex.justify-content-start.align-items-center
                      b-link.stretched-link(
                        :href='item.attributes.document_url'
                        target='_blank')
                      
                      .panel-picture.mr-4
                        b-avatar.wc-bubble.wc-bubble-xs.wc-bubble-br.mr-3.shadow(
                          variant='white'
                          alt='')
                          template(#default)
                            .wc-bubble-content.wc-bubble-linked.bg-primary
                              .i-wrapper
                                i.fal.fa-fw.fa-15x.fa-file-pdf.text-white
                      .panel-info.d-flex.flex-column.flex-fill
                        .panel-info-report.mb-1
                          .font-weight-bold
                            i.fal.fa-fw.fa-calendar-day.text-muted
                            |  {{ $t('activerecord.models.machine/report.one') }}
                          span.text-muted
                            |  {{ $t('shared.from') }}
                          |  {{ item.attributes.from }}
                          span.text-muted
                            |  {{ $t('shared.to') }}
                          |  {{ item.attributes.to }}

                    .menu.position-absolute(v-if='policies.destroy')
                      b-dropdown.ls-jobtero-item-menu(
                        toggle-tag='div'
                        toggle-class='p-0 text-muted shadow-none'
                        variant='transparent'
                        menu-class='border-0 p-0'
                        right
                        no-caret)
                        template(#button-content)
                          i.fal.fa-fw.fa-2x.fa-ellipsis-h.text-muted
                        b-dropdown-item(
                          v-if='item.attributes.policies.destroy !== false'
                          link-class='py-3'
                          :disabled='item.isLoading'
                          @click.stop.prevent='rowDelete(item, index, $event)')
                          i.fal.fa-fw.fa-trash(
                            :class="item.attributes.discarded_at ? 'text-secondarydark' : 'text-primary'")
                          |  {{ $t('shared.actions.delete') }}

          template(v-if='!isLoading && !items.length')
            b-row
              b-col.d-flex.justify-content-center
                wc-loaders-infinite-no-results

    b-card-footer.border-0.bg-white
      wc-tables-footer(
        :total-items='pagination.totalItems'
        :p-page='pagination.pPage'
        :c-page.sync='pagination.cPage')

    loading(
      :active.sync='isLoading'
      :can-cancel-esc='true'
      :on-cancel='onCancel')
</template>

<script>
import Tabs from '@views/container/manager/machines/shared/tabs'
import Table from '@common/table'
import WcLoadersInfiniteNoResults from '@components/shared/loaders/infinite/WcLoadersInfiniteNoResults'
import apiReports from '@services/api/manager/machines/reports'

export default {
  name: 'manager-machines-reports-list',
  mixins: [Tabs, Table],
  props: {
    wns: {
      type: String,
      default: 'rep',
    },
  },
  components: {
    WcLoadersInfiniteNoResults,
  },
  methods: {
    apiParams(item = {}) {
      return {
        list: [{ machine_id: this.machine_id }, this.sortingParams],
        destroy: [{ machine_id: this.machine_id, id: this.$getDeep(item, 'attributes.sid') }],
      }
    },
  },
  data() {
    return {
      apiBase: apiReports,
      machine_id: this.$route.params.machine_id,
    }
  },
}
</script>

<style lang="scss">
.manager-machines-reports-list {
  .menu {
    top: 0.5rem;
    right: 0.5rem;
    z-index: 1;
  }
}
</style>
