import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/machines/:machine_id/reports', params, query, config)
  },

  destroy(axios, params, config) {
    return api.delete(axios, '/manager/machines/:machine_id/reports/:id', params, config)
  },
}
